@charset 'UTF-8';
@import './setting/variables';

.GPC0150 {
	.slick-track {
		margin:0 auto;
	}
    .title {
    	max-width:1284px;
    	margin:auto;
    	text-align:center;
    	h2 {
    		font-size: 40px;
			@include pxtoemLineHeight(40px, 48px);
    		@include font-family($font-semibold);
    		color:$color-black;
    		@include screen(custom, max, $max-md) {
    			font-size:36px;
				@include pxtoemLineHeight(36px, 44px);
    		}
    		@include screen(custom, max, $max-sm) {
    			font-size:20px;
				@include pxtoemLineHeight(20px, 26px);
    		}
    	}
    	@include screen(custom, max, $max-sm) {
    		padding-left:24px;
    		padding-right:0;
            text-align: left;
			[dir="rtl"] & {
				padding-left:0;
	    		padding-right:24px;
				text-align: right;
			}
    	}
    }
    &.component .component-outer-box {
        @include screen(custom, max, $max-sm) {
            padding-left:0;
            padding-right:0;
        }
		.slick-slider {
			.slick-arrow {
				[dir="rtl"] & {
	                transform: translate(0,-50%) rotate(180deg);
	            }
			}
		}
    }
    .product-list {
        overflow: hidden;
        width:calc(100% + 24px);
        margin-top:50px;
        margin-left:-12px;
        opacity:0;
		[dir="rtl"] & {
			margin-right:-12px;
			margin-left:0;
			@include screen(custom, max, $max-sm) {
				margin-right:0;
				margin-left:0;
			}
		}
        @include screen(custom, max, $max-sm) {
            width:100%;
	        margin-top:19px;
            margin-left:0;
        }
        &.slick-initialized {
            opacity:1;
        }
        .item {
            float:left;
            width:303px;
            margin: 0 12px;
            @include screen(custom, max, $max-sm) {
                width:190px;
				margin:0;
                padding: 0 5px;
            }
			[dir="rtl"] & {
				float:right;
			}
            .item-link {
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 30px 20px;
                border: 1px solid $line-gray;
                box-sizing: border-box;
                border-radius: 10px;
                /* Inside auto layout */
                flex: none;
                order: 1;
                flex-grow: 0;
                text-align: center;
                @include screen(custom, max, $max-sm) {
                    padding: 20px 15px;
                }
            }
            .visual {
                width:160px;
                height:160px;
                margin:0 auto 30px;
                @include screen(custom, max, $max-md) {
                    width:100%;
                    height: auto;
                }
                @include screen(custom, max, $max-sm) {
                    margin:0 auto 20px;
                }
                img {
                    width:100%;
                    max-width:160px;
                    @include screen(custom, max, $max-sm) {
						max-width:120px;
                    }
                }
				@include screen(custom, max, $max-sm) {
					img.pc {display:none !important;}
				}
				@include screen(custom, min, $min-md) {
					img.mobile {display:none !important;}
				}
            }
            .model-name {
                overflow: hidden;
                height:44px;
                text-overflow:ellipsis;
                display:block;
                display:-webkit-box;
                -webkit-line-clamp:2;
                -webkit-box-orient:vertical;
                color:$color-nightrider;
                @include font-family($font-regular);
                font-size: 18px;
				@include pxtoemLineHeight(18px, 22px);
                @include screen(custom, max, $max-sm) {
                    height:60px;
                    -webkit-line-clamp:3;
                    font-size: 16px;
                    @include pxtoemLineHeight(16px, 20px);
                }
            }
        }
    }
    // slick carousel stylesheet
    .slick-slider {
        button {
            appearance: none;
            border: none;
            background-color: transparent;
        }
        .slick-list {
            width:auto;
            overflow:hidden;
            @include screen(custom, max, 1444) {
                padding:0;
            }
            @include screen(custom, max, $max-sm) {
                padding: 0 20px;
            }
        }
        .slick-arrow {
            padding:0;
            line-height:0;
            color: $color-gray;
            overflow:hidden;
            width:22px;
            height:40px;
            position:absolute;
            z-index:99;
            top:50%;
            transform: translate(0,-50%);
            text-indent: 10em;
            @include screen(custom, max, $max-sm) {
                width:12px;
                height:22px;
            }
            &:not([disabled]):hover,
            &:not([disabled]):focus {
                color: $color-black;
            }
            &.slick-prev{
				@include screen(custom, min, $min-md) {
					margin-top:0;
				}
                @include screen(custom, max, $max-sm) {
                    top:-19px;
                    right:60px;
                    left:inherit;
                    width:24px;
                    height:24px;
                    background:url('/lg5-common-gp/images/common/icons/carousel-arrow-prev-m.svg') 0 0;
                    background-size:24px 24px;
                    [dir="rtl"] & {
						left: 60px;
						right: inherit;
                    }
                }
            }
            &.slick-next {
				@include screen(custom, min, $min-md) {
					margin-top:0;
				}
                @include screen(custom, max, $max-sm) {
                    top:-19px;
                    right:24px;
                    width:24px;
                    height:24px;
                    background:url('/lg5-common-gp/images/common/icons/carousel-arrow-next-m.svg') 0 0;
                    background-size:24px 24px;
                    [dir="rtl"] & {
						left: 24px;
						right: inherit;
                    }
                }
            }
			.icon {
				@include screen(custom, max, $max-sm) {
					display: none;
				}
			}
        }
    }
}
